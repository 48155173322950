import { Route, Routes } from "react-router-dom";
import HomeOne from "./Components/HomeOne";
import HomeTwo from "./Components/HomeTwo";
import About from "./Components/About";
import Contact from "./Components/Contact";
import Blogs from "./Components/Blogs";
import Blog from "./Components/Blogs/Blog";
import Team from "./Components/Team";
import TeamDetails from "./Components/TeamDetails";
import Error from "./Components/Error";
import Services from "./Components/Services";
import ServiceDetails from "./Components/Services/ServiceDetails";
import ServiceGutter from "./Components/Services/ServiceGutter";
import ServiceSiding from "./Components/Services/ServiceSiding";
import HomeThree from "./Components/HomeThree";
import HomeFour from "./Components/HomeFour";
import Project from "./Components/Project";
import ProjectDetails from "./Components/Project/ProjectDetails";
import ProjectGutter from "./Components/Project/ProjectGutter";
import ProjectSiding from "./Components/Project/ProjectSiding";
import FloatingButton from "./Components/FloatingButton/FloatingButton";

function Routers() {
  return (
    <>
    {/* <FloatingButton /> */}
    <Routes>
      <Route exact path="/" element={<HomeOne />} />
      <Route exact path="/home-two" element={<HomeTwo />} />
      <Route exact path="/home-three" element={<HomeThree />} />
      <Route exact path="/home-four" element={<HomeFour />} />
      <Route exact path="/about" element={<About />} />
      <Route exact path="/contact" element={<Contact />} />
      <Route exact path="/blogs" element={<Blogs />} />
      <Route exact path="/blogs/blog" element={<Blog />} />
      <Route exact path="/team" element={<Team />} />
      <Route exact path="/team-details" element={<TeamDetails />} />
      <Route exact path="/error" element={<Error />} />
      <Route exact path="/project" element={<Project />} />
      <Route exact path="/project/project-roof" element={<ProjectDetails />} />
      <Route exact path="/project/project-gutter" element={<ProjectGutter />} />
      <Route exact path="/project/project-siding" element={<ProjectSiding />} />
      <Route exact path="/services" element={<Services />} />
      <Route exact path="/services/services-siding" element={<ServiceSiding />} />
      <Route
        exact
        path="/services/services-roofing"
        element={<ServiceDetails />}/>
        <Route
        exact
        path="/services/services-gutter"
        element={<ServiceGutter />}/>
    </Routes>
    </>
  );
}

export default Routers;
