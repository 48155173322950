import React, { useState } from "react";
import axios from "axios";

function MainWid() {
  const baseUrl = "https://contactapi.academyd-signs.com/dsigns_mailcontact_api/contact/"
  const [contactData, setContactData] = useState({
    'full_name': '',
    'email': '',
    'query_txt': '',
    'website_mail': 'Gabrielguallpa34@gmail.com',
    'website_name': 'United Restoration LLC',
    'status': '',
  });
  const handleChange = (event) => {
    setContactData({
      ...contactData,
      [event.target.name]: event.target.value
    });
  }

  const submitForm=()=>{
    const contactFormData = new FormData();
    contactFormData.append("full_name", contactData.full_name);
    contactFormData.append("email", contactData.email);
    contactFormData.append("query_txt", contactData.query_txt);
    contactFormData.append("website_mail", "javierlibre13@gmail.com");
    contactFormData.append("website_name", "United Restoration LLC");
    try{
      axios.post(baseUrl, contactFormData).then((response)=>{
        setContactData({
          'full_name': '',
          'email': '',
          'query_txt': '',
          'status': 'success',
        });
        alert("Thanks for contacting us.");
      });
    }catch(error){
      console.log(error);
      setContactData({'status': 'error'});
    }
  };
  return (
    <section className="contact-area pt-120 pb-120">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6 col-lg-10">
            <div
              className="contact-form-wrap"
              style={{
                backgroundImage: `url(${require("../../assets/img/images/contact_form_bg.jpg")})`,
              }}
            >
              <h2 className="title">Contact With Us</h2>
              <p>Send us a message and we' ll respond as soon as possible</p>
              {/* <a href="tel:+14758009366">+1-475-800-9366</a><br />
                      <a href="mailto:erick.a@academyd-signs.com">erick.a@academyd-signs.com</a>
              <p>This Site and its domain is available, contact us for more information</p> */}
              
                      
                    
              <div onSubmit={submitForm} className="contact-form">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="firstName"
                        type="text"
                        placeholder="Name"
                        onChange={handleChange}
                        value={contactData.full_name}
                        name="full_name"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="lastName"
                        type="text"
                        placeholder="Last Name*"
                      />
                    </div>
                  </div> */}
                  <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="email"
                        type="email"
                        placeholder="Email Address*"
                        onChange={handleChange}
                        value={contactData.email}
                        name="email"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="col-md-6">
                    <div className="form-grp">
                      <input
                        id="phone"
                        type="text"
                        placeholder="Phone Number*"
                      />
                    </div>
                  </div> */}
                </div>
                {/* <div className="form-grp">
                  <input id="subject" type="text" placeholder="Subject" />
                </div> */}
                <div className="form-grp">
                  <textarea
                    id="message"
                    placeholder="Your Message here"
                    onChange={handleChange}
                    value={contactData.query_txt}
                    name="query_txt"
                    required
                  ></textarea>
                </div>
                <button className="btn" onClick={submitForm} type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-10">
            <div className="contact-info-wrap">
              <h2 className="title">Need Any Help?</h2>
              <p>Call us or message and we' ll respond as soon as possible</p>
              {/* <p>This Site and its domain is available, contact us for more information</p> */}
              <ul className="list-wrap">
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="content">
                      <a href="tel:+14752382974">(475)238-2974</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    <div className="icon">
                      <i className="fas fa-envelope"></i>
                    </div>
                    <div className="content">
                      <a href="mailto:Gabrielguallpa34@gmail.com">Gabrielguallpa34@gmail.com</a>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="contact-info-item">
                    
                  <a href="https://maps.app.goo.gl/eNqhY6BZ1D8EYg6z9">
                  <div className="icon">
                      <i className="fas fa-map-marker-alt"></i>
                    </div>
                  </a>
                  </div>
                </li>
              </ul>
            </div>
            <div id="contact-map">
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2995.8376935358615!2d-72.87208810000001!3d41.3341426!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e7d7345251642d%3A0x51352757da9d006e!2s188%20Gando%20Dr%2C%20New%20Haven%2C%20CT%2006513%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sco!4v1739192386684!5m2!1ses-419!2sco"
                allowFullScreen
                loading="lazy"
              ></iframe>              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MainWid;
