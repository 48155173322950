import React from "react";
import SliderCom from "../Helpers/SliderCom";

function TestimonialArea({ className }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    autoplay: true,
    arrows: false,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };
  return (
    <section
      className={`inner-testimonial-area parallax pb-120 ${className || ""}`}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-6">
            <div className="section-title text-center mb-50">
              {/* <span className="sub-title">Our Testimonial</span> */}
              <h2 className="title">What Our Client Feedback</h2>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-10">
            <div className="testimonial-inner">
              <div className="testimonial-active-two position-relative">
                <SliderCom settings={settings}>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require(`../../assets/img/images/h2_testi_avatar01.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>
                      I was so impressed with the professionalism and efficiency of United Restoration when they repaired my leaky roof. They quickly identified the problem, explained the repair process in detail, and completed the work on time and within budget. My roof is now in great condition, and I'm so grateful for their excellent service.
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Darrell Steward</h4>
                        <span>Roof Repair</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require(`../../assets/img/images/h2_testi_avatar02.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>
                      When it came time to replace my aging roof, I knew I could trust United Restoration. They provided a thorough inspection, offered expert advice, and installed a high-quality new roof that exceeded my expectations. The entire process was smooth and stress-free, and I'm confident that my home is now protected for years to come.
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Karikoka Ahli</h4>
                        <span>Roof Replacement</span>
                      </div>
                    </div>
                  </div>
                  <div className="testimonial-item-two">
                    <div className="testimonial-avatar-two">
                      <img
                        src={require(`../../assets/img/images/h2_testi_avatar03.jpg`)}
                        alt=""
                      />
                    </div>
                    <div className="testimonial-content-two">
                      <div className="content-top">
                        <div className="icon">
                          <i className="fas fa-quote-left"></i>
                        </div>
                        <div className="rating">
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                          <i className="fas fa-star"></i>
                        </div>
                      </div>
                      <p>
                      I was amazed at the difference a professional roof cleaning made. United Restoration did a fantastic job removing moss, algae, and other debris from my roof, restoring its original appearance. My roof looks like new, and I know it's now better protected from damage.
                      </p>
                      <div className="content-bottom">
                        <h4 className="title">Robert C. Simmons</h4>
                        <span>Roof Cleaning</span>
                      </div>
                    </div>
                  </div>
                </SliderCom>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="testimonial-avatar-wrap">
          <ul className="list-wrap">
            <li>
              <img
                src={require(`../../assets/img/images/h4_testi_avatar01.png`)}
                className="layer"
                data-depth="0.1"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/images/h4_testi_avatar02.png`)}
                className="layer"
                data-depth="0.2"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/images/h4_testi_avatar03.png`)}
                className="layer"
                data-depth="0.05"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/images/h4_testi_avatar03.png`)}
                className="layer"
                data-depth="0.2"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/images/h4_testi_avatar02.png`)}
                className="layer"
                data-depth="0.05"
                alt=""
              />
            </li>
            <li>
              <img
                src={require(`../../assets/img/images/h4_testi_avatar01.png`)}
                className="layer"
                data-depth="0.1"
                alt=""
              />
            </li>
          </ul>
        </div> */}
      </div>
    </section>
  );
}

export default TestimonialArea;
